<template>
  <div v-if="showShiftyBar">
      <!--    Desktop-->
      <div
          v-if="shiftyBar.page_position === 'shiftyBar' && shiftyBar.is_desktop_active"
          class="hidden sm:flex shifty-bar"
          :style="{
                              height: shiftyBar?.height + 'px',
                              'background-image': shiftyBar?.desktop_stripe_image ? 'url(' + shiftyBar.desktop_stripe_image + ')' : 'none',
                              'background-color': shiftyBar?.desktop_stripe_image ? 'transparent' : (shiftyBar.color ? shiftyBar.color : '#fff'),                              color: shiftyBar?.color,
                              'font-size': shiftyBar?.fontSize + 'px'
                        }"
      >
        <div class="shifty-bar__container container py-2 sm:pr-3">
          <div class="shifty-bar__wrapper flex-col w-full items-center justify-center">
            <div v-if="shiftyBar?.desktop_image !== ''" class="w-1/4 items-center justify-center">
              <img class="w-auto px-2 inline-block shifty-bar-image" :src="shiftyBar?.desktop_image" :alt="shiftyBar?.title" />
            </div>
            <div class=" inline-flex items-center" :class="shiftyBar.desktop_image !== '' ? 'w-3/4': 'w-full'">
              <p class="text-sm w-3/4" :style="{color: shiftyBar.shiftybarTextColorValue}">
                {{ shiftyBar?.text ?? " Hallo das ist ein Test! Hallo das ist ein Test! Hallo das ist ein Test!" }}
              </p>
              <div  v-if="shiftyBar?.url"
                  class="shifty-bar__button w-1/4 justify-center"
                  @click="handleClick(shiftyBar?.url)"
                  :style="{
                              width: shiftyBar?.width + 'px',
                              height: shiftyBar?.height + 'px',
                              'background-color': shiftyBar?.right_button_background_color,
                              color: shiftyBar?.right_button_text_color,
                              'font-size': shiftyBar?.fontSize + 'px'
                                                }"
              >{{ shiftyBar.right_button_text ? shiftyBar.right_button_text : "Klick hier" }}</div
              >
            </div>
          </div>

          <div class="closeshifty-bar sm:pr-3" @click="closeShiftyBar">x</div>
        </div>
      </div>

      <!--    Mobile-->
      <div
          v-if="shiftyBar.is_mobile_active"
          class="flex sm:hidden shifty-bar"
          :style="{
                    height: shiftyBar?.height + 'px',
                    'background-image': shiftyBar.mobile_stripe_image ? 'url(' + shiftyBar.mobile_stripe_image + ')' : 'none',
                    'background-color': shiftyBar.mobile_stripe_image ? 'transparent' : (shiftyBar.color ? shiftyBar.color : '#fdc600'),
                      color: shiftyBar?.color,
                    'font-size': shiftyBar?.fontSize + 'px'
                    }"
      >
        <div class="shifty-bar__container container">
          <div class="shifty-bar__wrapper w-full flex-col items-end justify-end">
            <div v-if="shiftyBar.mobile_image !== ''" class="w-1/3 items-end justify-end" >
              <img class="w-auto px-2 inline-block" :src="shiftyBar?.mobile_image" :alt="shiftyBar?.title" />
            </div>
            <div :class="shiftyBar.mobile_image ? 'w-2/3': 'w-full'">
              <p class="text-sm" :style="{color: shiftyBar.shiftybarTextColorValue}">
                {{ shiftyBar?.text ?? " Hallo das ist ein Test! Hallo das ist ein Test! Hallo das ist ein Test!" }}
              </p>
              <div v-if="shiftyBar?.url"
                  class="shifty-bar__button"
                   @click="handleClick(shiftyBar?.url)"
                   :style="{
                            width: shiftyBar?.width + 'px',
                            height: shiftyBar?.height + 'px',
                            'background-color': shiftyBar?.right_button_background_color,
                            color: shiftyBar?.right_button_text_color,
                            'font-size': shiftyBar?.fontSize + 'px'
                                                }"
              >{{ shiftyBar.right_button_text ? shiftyBar.right_button_text : "Klick hier" }}</div
              >
            </div>
          </div>

          <div class="closeshifty-bar" @click="closeShiftyBar">x</div>
        </div>
      </div>
    </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import { useRouter } from "vue-router";
export default {
  name: "ShiftyBarProduct",
  props: {
    shiftyBarData: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const router = useRouter();
    const showShiftyBar = ref(false);

    const shiftyBar = computed(() => {

      if (props.shiftyBarData && props.shiftyBarData.length > 0) {
        return props.shiftyBarData[0]
      }
      return [];
    });
    const closeShiftyBar = () => {
      showShiftyBar.value = false;
    };

    const handleClick = async (url) => {
      if (isInternalRoute(url)) {
        const sku = extractSKU(url);
        if (sku) {
         await router.push({
            name: "product-details",
            params: { seo_url: sku },
          });
        } else {
          console.error('SKU not found in the URL:', url);
        }
      } else {
        window.location.href = url;
      }
    };

    const isInternalRoute = (url) => {
      return url.includes('/praemien/produkt/');
    };

    const extractSKU = (url) => {
      const matches = url.match(/\/praemien\/produkt\/[^_]+_([^/]+)/);
      return matches ? matches[1] : null;
    };


    const checkShiftyBarData = () => {
      if (props.shiftyBarData && props.shiftyBarData.length > 0) {
        showShiftyBar.value = true;
      }
    };
    onMounted(() => {
      checkShiftyBarData();
    });
    watch(() => props.shiftyBarData, () => {
      checkShiftyBarData();
    });

    return {
      shiftyBar,
      showShiftyBar,
      closeShiftyBar,
      handleClick
    };
  }
};
</script>
<style lang="postcss" scoped>
.shifty-bar {
  position: relative;
  height: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.shifty-bar__shifty-bar {
  font-weight: bold;
  font-family: "PAYBACKLight", sans-serif;
  font-size: 30px;
  margin-right: 30px;
}

.shifty-bar__button {
  border-radius: 0.2rem;
  background: #c1002b;
  display: flex;
  height: 40px;
  color: #fff;
  white-space: nowrap;
  font-weight: bold;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  min-width: 160px;
  width: auto;
  margin-left: 20px;
}
.shifty-bar__container {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.shifty-bar__wrapper {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.closeshifty-bar {
  color: #0046aa;
  font-weight: bold;
  font-size: 1.2rem;
  position: relative;
  right: 0;
  top: -40px;
  cursor: pointer;
}
.shifty-bar-image {
  height: 100px;
}
@media screen and (max-width: 767px) {
  .shifty-bar {
    padding: 20px 30px 0 10px;
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #fdc600;
  }
  .shifty-bar .container {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .shifty-bar__shifty-bar {
    font-weight: bold;
    font-family: "PAYBACKLight", sans-serif;
    font-size: 24px;
  }

  .shifty-bar__button {
    border-radius: 0.2rem;
    background: #c1002b;
    display: flex;
    height: 30px;
    color: #fff;
    white-space: nowrap;
    font-weight: bold;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0;
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .shifty-bar {
    padding: 20px 30px 0 10px;
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #fdc600;
  }
  .shifty-bar .container {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .shifty-bar__shifty-bar {
    font-weight: bold;
    font-family: "PAYBACKLight", sans-serif;
    font-size: 24px;
  }

  .shifty-bar__button {
    border-radius: 0.2rem;
    background: #c1002b;
    display: flex;
    height: 30px;
    color: #fff;
    white-space: nowrap;
    font-weight: bold;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin: 20px 0;
  }
  .closeshifty-bar {
    color: #0046aa;
    font-weight: bold;
    font-size: 1.2rem;
    position: absolute;
    right: 0;
    top: -20px;
    cursor: pointer;
  }
}

/* END 10% BANNER */
</style>
