<template>
  <div>
    <product-detailed :product="product" v-if="detailed"></product-detailed>
    <product-short :product="product" :index="index" v-else></product-short>
  </div>
</template>

<script>
  import ProductShort from "@/components/Product/Short";
  import ProductDetailed from "@/components/Product/Detailed";
  export default {
    props: {
      product: {
        type: Object,
      },
      detailed: {
        type: Boolean,
        default: false,
      },
      index: Number
    },
    name: "Product",
    components: { ProductShort, ProductDetailed },
    setup() {
      return {};
    },
  };
</script>
