<template>
  <Button v-if="expressCheckout" :text="'Direkt zur Kassa'" :css-class="'blue'" @click="addToCart"></Button>
  <Button v-else-if="cartMobile" :text="'Hinzufügen'" :css-class="'white'" @click="addToCart"></Button>
  <Button v-else :text="'In den Warenkorb'" @click.prevent @click.stop @click="addToCart" :css-class="'green'"></Button>
</template>
<script>
import Button from "@/components/Button/Button";
import { inject, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "AddToCartButton",
  components: { Button },
  props: {
    productSku: String,
    detailPage: {
      type: Boolean,
      default: false,
    },
    expressCheckout: {
      type: Boolean,
      default: false,
    },
    cartMobile: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    // Inject the value of the coOrder property provided by the ProductList component
    const coOrderProducts = inject("coOrder", false);

    const redirectToShoppingCartPage = () => {
      setTimeout(function () {
        router.push({
          name: "shopping-cart",
          params: {},
        });
      }, 500);
    };

    const redirectToExpressCheckoutPage = () => {
      // Redirect to express checkout page
      setTimeout(function () {
        router.push({
          name: "express-bestellen",
          params: {},
        });
      }, 500);
    };

    const displayCoorderNotification = () => {
      let notification = {
        code: "COORDER_ADDED",
        type: "success",
      };
      store.dispatch("notification/set", notification);
      window.scrollTo(0, 0);
    };
    const cartItems = computed(() => {
      return store.getters["cart/getItems"];
    });

    const addToCart = async () => {
      const cart = {
        item: {
          product_sku: props.productSku,
          qty: 1,
        },
      };

      if (props.expressCheckout) {
        await store.dispatch("expressCheckoutCart/addItem", cart);
        redirectToExpressCheckoutPage();
      } else {
        await store.dispatch("cart/addItem", cart);
        let cartItemIndex = cartItems?.value.findIndex((item) => {
          return item.product_sku == props.productSku
        })
        if (cartItemIndex >= 0) {
          store.dispatch("cart/setCartArrayIndex", cartItemIndex)
        } 
        const notification = store.getters["notification/get"];
        if (props.detailPage) {
          if (notification && notification.code && notification.code == "NO_MIXED") {
            return;
          } else {
            redirectToShoppingCartPage();
          }
        }
        if (coOrderProducts) {
          // Display a notification to notify the user that a coorder product was added
          displayCoorderNotification();
        }
      }
    };

    return {
      addToCart,
    };
  },
};
</script>
<style lang="postcss" scoped>
button {
  white-space: nowrap;
}
</style>
