<template>
 <div class="product">
  <shifty-bar v-if="shiftyBarData" :shiftyBarData="shiftyBarData"  />
  <div class="page-content-white">
   <div class="page-content-white__container">
    <div class="grid lg:grid-cols-3 md:grid-cols-3 xs:grid-cols-1 sm:grid-cols-1 gap-4 page-content-white__container--product-info-container">
     <div class="col-span-2">
      <div class="grid grid-cols-1 gap-1" :class="{ 'grid-cols-4': images.length > 1 }">
       <div class="col-span-1 carousel-preview-dektop" v-if="images.length > 1">
        <div v-for="(img, index) in images" :key="index" class="pic ml-auto mr-auto justify-center items-center" @click="() => showImg(index)">
         <app-image :alt="product - image" class="flex flex-col justify-center items-center" v-show="index < 3" :src="typeof img === 'string' ? img : img.src" />
        </div>
        <div class="flex flex-col justify-center items-center pt-5 pb-5" v-if="showMoreCount > 3" @click="() => showImg(3)">
         <div>
          <img src="../../assets/img/bild-empty.png" alt="Platzhalter" />
         </div>
         <div>{{ showMoreCount - 3 }} weitere</div>
        </div>
       </div>
       <div class="col-span-1 relative" :class="{ 'col-span-4 mobile:col-span-3': images.length > 1 }">
        <div v-if="product.brand !== null">
         <app-image class="brand-image" :alt="`${product?.brand?.name}`" :src="`${product?.brand?.image}`"></app-image>
        </div>
        <vue-easy-lightbox :visible="visible" :imgs="images" :index="index" @hide="handleHide"></vue-easy-lightbox>
        <div v-if="images.length > 0" @click="() => showImg(index)">
         <image-slider :showPagination="{ clickable: true }" :modelValue="modelValue" />
        </div>
       </div>
      </div>
     </div>
     <div class="col-span-1 align-top product">
      <div class="product__info">
       <div class="product__info__badge" v-if="product.badge">
        <div v-if="product.badge.label" :class="product.badge.label !== 'NEU' ? 'product__info__badge-red' : 'product__info__badge-neu'">
         <span>{{ product.badge.label }}</span>
        </div>
       </div>
       <div class="product__info-name">
        <h2>{{ product.name }}</h2>
       </div>
       <div>
        <div v-if="product.price">
         <div class="grid grid-cols-1 product__info-price">
          <div>
           <div class="product__info-price-mrsp" v-if="product.price.primary.MRSP">
            <span
             :class="{
              'product__info-price-mrsp-strike-through': product.price.primary.MRSP
             }"
             >statt UVP {{ product.price.primary.MRSP.value }} °P</span
            >
           </div>
           <div class="product__info-price-default" v-if="product.price.primary.DEFAULT">{{ product.price.primary.DEFAULT.value }} °P</div>
           <div class="product__info-price-remaining" v-if="product.remaining_price">oder {{ product.remaining_price.label }}</div>
          </div>
         </div>
         <div v-if="product.prodGroup != 'E_GIFTCARD'" class="product__info-deliverytext">
          <span>Kostenloser Versand</span>&nbsp;{{ product.delivery_information.delivery_text !== "keine" ? product.delivery_information.delivery_text : "" }}
         </div>
         <select-input
          v-if="isDigital"
          :options="possibleArticles"
          :value="selectedArticle"
          :name="possibleArticles.label"
          placeholder="Andere Artikel"
          @change="redirectToProductDetailPage(possibleArticles, $event)"
         ></select-input>
         <div class="product__info-btn-warenkorb mt-1">
          <div class="col-span-1" v-if="product.availability_information.status_id != 'NO_STOCK'">
           <AddToCartButton
            :product-sku="product.sku"
            :detail-page="true"
            data-rewardsshop-action="cart-add"
            data-rewardsshop-cart-add-quantity="1"
            :data-rewardsshop-cart-add-location="route.meta.trackingName"
           ></AddToCartButton>
          </div>
          <div class="col-span-1" v-if="isDigital && product.availability_information.status_id != 'NO_STOCK'">
           <AddToCartButton
            :product-sku="product.sku"
            :detail-page="true"
            data-rewardsshop-action="cart-add"
            data-rewardsshop-cart-add-quantity="1"
            :data-rewardsshop-cart-add-location="route.meta.trackingName"
            :express-checkout="true"
           ></AddToCartButton>
          </div>
          <div class="product__info-not-available col-span-2" v-if="product.availability_information.status_id == 'NO_STOCK'">
           <div class="py-4">Derzeit nicht verfügbar</div>
          </div>
         </div>

         <div class="product__info-bottomtext pt-4">
          <div v-if="product?.sold_by != product?.shipped_by">
           <span class="mr-3" v-if="product.sold_by"
            >Verkauf durch: <span class="text-blue">{{ product.sold_by }} </span></span
           >

           <span class="-mr-12" v-if="product.shipped_by"
            >Versand durch: <span class="text-blue">{{ product.shipped_by }}</span></span
           >
          </div>

          <span v-if="product?.sold_by && product?.shipped_by && product.sold_by != '' && product.shipped_by != '' && product?.sold_by === product?.shipped_by"
           >Verkauf und Versand durch: <span class="text-blue">{{ product.sold_by }} </span></span
          >
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
  <div class="page-content-lightestblue">
   <div class="page-content-lightestblue__container pb-10">
    <div class="product-description">
     <div class="col-span-full mobile:col-span-2">
      <h2>Produktbeschreibung</h2>
      <div v-html="product.description"></div>
      <h2 v-if="product.scope_of_delivery !== null">Lieferumfang</h2>
      <div v-if="product.scope_of_delivery !== null" v-html="product.scope_of_delivery"></div>
     </div>
     <div class="col-span-full mobile:col-span-1">
      <h2>Eigenschaften</h2>
      <div v-html="product.product_details"></div>
      <h2 v-if="product.technical_details !== null">Technische Details</h2>
      <div v-if="product.technical_details !== null" v-html="product.technical_details"></div>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
 import AddToCartButton from "@/components/Button/AddToCartButton";
 import AppImage from "@/components/Image/Image";
 import ImageSlider from "@/components/ImageSlider/ImageSlider";
 import { computed, onMounted, onUnmounted, ref } from "vue";
 import { useStore } from "vuex";
 import { useRouter, useRoute } from "vue-router";
 import VueEasyLightbox from "vue-easy-lightbox";
 import SelectInput from "@/components/Inputs/SelectInput";
 import ShiftyBar from "@/components/ShiftyBar/ShiftyBarProduct.vue";
 import { useI18n } from "vue-i18n";

 export default {
  props: {
   product: {
    type: Object,
    required: true
   }
  },
  name: "DetailedProduct",
  components: {
   AddToCartButton,
   ImageSlider,
   AppImage,
   VueEasyLightbox,
   SelectInput,
   ShiftyBar
  },
  setup(props) {
   const store = useStore();
   const router = useRouter();
   const route = useRoute();
   const index = ref(0);
   const visible = ref(false);
   const modelValue = ref(0);
   const selectedArticle = ref("");
   const possibleArticles = ref("");
   const windowSize = ref(window.innerWidth);
   const { t } = useI18n();
   onMounted(() => {
    window.addEventListener("resize", () => {
     windowSize.value = window.innerWidth;
    });
   });
   onUnmounted(() => {
    window.removeEventListener("resize", () => {
     windowSize.value = window.innerWidth;
    });
   });
   const shiftyBarData = computed(() => {
     if(props.product?.shifty_bar === undefined){
       return null
     }
    return props.product?.shifty_bar;
   });
   const isDigital = computed(() => {
    const product = props.product;
    if ((product.prodGroup && product.prodGroup === "E_GIFTCARD") || product?.prodGroup === "PAYBACK_ARTICLE") {
     productDetails();
     return true;
    }
    return false;
   });

   const productDetails = async () => {
    const sku = route?.params?.seo_url?.split("_").pop();

    const products = await store.dispatch("catalog/getDifferentDenominationProducts", sku);

    possibleArticles.value = products?.linked_products.map((product) => ({ ...product, val: product.id })).filter((a, i, self) => self.findIndex((s) => a.id === s.id) === i);

    products.linked_products.forEach((element) => {
     if (element.id === products.id) {
      selectedArticle.value = element.id.toString();
     }
    });
   };

   const redirectToProductDetailPage = (productDetail, event) => {
    const filteredData = productDetail.find((obj) => {
     return obj.id === Number(event.target.value);
    });
    selectedArticle.value = filteredData.id.toString();
    router.push({
     name: "product-details",
     params: { seo_url: filteredData.url }
    });
   };

   const showImg = (i) => {
    if (i >= 0) {
     index.value = i;
     visible.value = true;
    } else {
     modelValue.value = i;
    }
   };

   const handleHide = () => {
    visible.value = false;
   };

   // const sliderHeight = computed(() => {
   //       let imageHeight = "";
   //       if (window.screen.height > 960) {
   //             imageHeight = "480px";
   //       }
   //       if (window.screen.height > 767) {
   //             imageHeight = "20rem";
   //       }
   //       return imageHeight;
   // });

   return {
    visible,
    t,
    index,
    modelValue,
    selectedArticle,
    possibleArticles,
    redirectToProductDetailPage,
    showImg,
    handleHide,
    isDigital,
    // sliderHeight,
    windowSize,
    images: computed(() => {
     return store.getters["sliderImages/getImages"];
    }),
    showMoreCount: computed(() => {
     let images = store.getters["sliderImages/getImages"];
     return images.length;
    }),
    route,
     shiftyBarData,
   };
  }
 };
</script>
<style lang="postcss" scoped>
 .page-nav-menu {
  z-index: 999 !important;
 }

 .product {
  .page-content-white__container--product-info-container {
   @apply gap-4 grid;
   grid-template-columns: 1fr 1fr 45%;
   @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
   }
  }
  .product-description {
   @apply gap-2 grid pt-0;
   grid-template-columns: 1fr 1fr 45%;
   font-size: 14px;
   line-height: 27px;
   font-weight: normal;
   h2 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 27px;
    /* or 193% */
    font-weight: bold;
    color: #000000;
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
    @apply ml-0;
   }
   p {
    margin: 0 0 1rem 0;
   }
   @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
   }
  }
  @apply text-black;
  &__info {
   &__badge {
    @apply bg-badge-neu mt-2 text-center rounded-lg inline-block;
    font-family: PAYBACKLight, sans-serif;
    margin-bottom: 1.4rem;
    font-size: 20px;
    line-height: 20px;
    &-red {
     @apply bg-red-light rounded-lg;
     padding: 8px 12px 5px 12px;
    }
    &-neu {
     @apply rounded-lg;
     padding: 8px 12px 5px 12px;
    }

    span {
     @apply text-white rounded-lg;
     padding: 0;
    }
   }
   &-name {
    h2 {
     font-size: 32px;
     line-height: 35px;
     color: #000;
    }
   }
   &-price {
    @apply border-b border-blue-lighter py-4;
    & > div {
     @apply inline-flex justify-start items-end flex-wrap;
    }
    &-default {
     @apply mr-3 flex justify-start items-end top-1 relative;
     font-size: 24px;
     font-family: PAYBACKLight, sans-serif;
     color: #000;
    }
    &-mrsp {
     @apply relative w-full;
     color: #4b4b4d;
     &-strike-through {
      @apply float-left relative;
      color: #4b4b4d;
      &:before {
       content: "";
       position: absolute;
       left: 0;
       right: 0;
       top: 0;
       bottom: 0;
       background: url(../../assets/img/strikethrough.svg) center center no-repeat;
       background-size: 100% 100%;
      }
     }
    }
    &-remaining {
     @apply flex justify-start items-end;
     font-family: "Open Sans", sans-serif;
     font-style: normal;
     font-weight: normal;
     font-size: 14px;
     line-height: 19px;
    }
    &-shipping {
     @apply flex flex-col justify-center items-center;
    }
   }

   &-deliverytext {
    @apply py-4;
    span {
     @apply text-green-light;
    }
   }
   &-btn {
    &-warenkorb {
     @apply py-0 grid grid-cols-2 gap-2;
     @media (max-width: 992px) {
      @apply grid-cols-1;
     }
    }
   }
   &-bottomtext {
    @apply pb-5;
    a {
     @apply text-blue;
    }
   }
   &-not-available {
    @apply text-red-dark;
   }
  }
  @media screen and (max-width: 767px) {
   .page-content-white__container,
   .page-content-lightestblue__container {
    width: 90%;
   }
  }
  .brand-image {
   position: absolute;
   top: 20px;
   left: 20px;
   height: auto;
   width: 70px;

   z-index: 9;
   @media screen and (max-width: 767px) {
    left: 20px;
    width: 50px;
   }
  }
 }
 li {
  margin-bottom: 0.25rem;
 }
</style>
<style lang="postcss">
 .carousel-preview-dektop {
  @media screen and (max-width: 767px) {
   display: none;
  }
 }
 .pic {
  @apply cursor-pointer;
 }
 .product {
  ul {
   list-style: none;
  }
  li {
   margin-bottom: 0.25rem;
   background-image: url(../../assets/img/bullet.svg);
   background-repeat: no-repeat;
   background-position: 0px 10px;
   padding-left: 1rem;
  }
  ul[style*="list-style-type: disc;"] {
   margin-left: 1rem;
   li {
    background-image: none;
   }
  }
 }
 .vueperslides--touchable .vueperslides__track,
 .vueperslides .vueperslide {
  cursor: pointer !important;
 }
</style>
<style lang="postcss">
 .product .product-description {
  p {
   margin: 0 0 1rem 0;
  }
 }
</style>
