<template>
      <div class="text-input__row">
            <select v-model="innerValue">
                  <option disabled value="">Bitte auswählen</option>
                  <option v-for="opt in options" v-bind:value="opt.val" v-bind:key="opt.id">
                        {{ opt.label }}
                  </option>
            </select>
      </div>
</template>

<script>
      export default {
            name: "SelectInput",
            data: function (props) {
                  return {
                        innerValue: props.value
                  };
            },
            components: {},
            props: {
                  vid: {
                        type: String
                  },
                  rules: {
                        type: [Object, String],
                        default: ""
                  },
                  options: {
                        type: Array,
                        required: true
                  },
                  label: {
                        type: String,
                        default: ""
                  },
                  name: {
                        type: String,
                        default: ""
                  },
                  title: {
                        type: String,
                        default: ""
                  },
                  // must be included in props
                  value: {
                        type: null
                  }
            },
            computed: {
                  required() {
                        return this.rules.indexOf("required") !== -1;
                  }
            },
            watch: {
                  // Handles internal model changes.
                  innerValue(newVal) {
                        this.$emit("input", newVal);
                  },
                  // Handles external model changes.
                  value(newVal) {
                       this.innerValue = newVal;
                  }
            }
      };
</script>
<style lang="postcss" scoped>
      .text-input__row {
            @apply flex justify-between items-start text-left w-full flex-col;
            @screen mobileM {
                  @apply items-center flex-row;
            }

            select {
                  @apply border rounded-md border-highlighted p-2 w-full text-black;
                  height: 48px;
                  &::placeholder {
                        @apply uppercase;
                  }
                  @screen mobileM {
                        @apply w-2/3;
                  }
            }
            select:only-child {
                  @apply w-full;
            }
      }
</style>
